import { Result } from 'antd';

export default function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Result
        status={404}
        title="Resource not found"
        subTitle="The resource you have requested does not exist, has moved, or is not available."
      />
    </div>
  );
}
